// /*------- 5. Section title style  -------*/

/*------- 5. Section title style  -------*/

.section-title {
  // Styles for extra small screens
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    margin: 0;

    // Styles for extra small screens
    @media #{$xs-layout} {
      font-size: 24px;
      line-height: 30px;
      &.mb-50 {
        margin-bottom: 30px;
      }
    }

    // Styles for medium screens
    @media #{$md-layout} {
      font-size: 28px; // Adjust font size for medium screens
      line-height: 36px; // Adjust line height for readability
    }

    // Styles for large screens
    @media #{$lg-layout} {
      font-size: 30px; // Maintain larger font size for larger screens
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%; // Center vertically relative to the text
      transform: translateY(-50%); // Adjust for perfect centering
      width: 80px; // Default width for larger screens
      height: 2px;
      content: "";
      background-color: #000;

      // Styles for medium screens
      @media #{$md-layout} {
        width: 50px; // Adjust width for medium screens
      }

      // Styles for large screens
      @media #{$lg-layout} {
        width: 80px; // Maintain original width for larger screens
      }
    }

    &::before {
      left: -100px; // Default position
      @media #{$xs-layout} {
        left: -40px; // Closer to the text on smaller screens
        width: 30px;
      }
      @media #{$md-layout} {
        left: -60px; // Adjust for medium screens
      }
      @media #{$lg-layout} {
        left: -80px; // Further adjustment for larger screens
      }
    }

    &::after {
      right: -100px; // Default position
      @media #{$xs-layout} {
        right: -40px; // Closer to the text on smaller screens
        width: 30px;
      }
      @media #{$md-layout} {
        right: -60px; // Adjust for medium screens
      }
      @media #{$lg-layout} {
        right: -80px; // Further adjustment for larger screens
      }
    }
  }

  &.mb-55 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;

    &.grey {
      color: #808080;
    }
  }

  &.no-border {
    h2 {
      font-size: 36px;
      &:before,
      &:after {
        display: none; // Hide borders when no-border class is applied
      }
    }
  }
}



// .section-title {
//   @media #{$xs-layout} {
//     &.mb-50 {
//       margin-bottom: 30px;
//     }
//   }
//   h2 {
//     font-size: 30px;
//     font-weight: 600;

//     position: relative;

//     display: inline-block;

//     margin: 0 0 0;
//     @media #{$xs-layout} {
//       font-size: 24px;
//       line-height: 30px;
//       &.mb-50 {
//         margin-bottom: 30px;
//       }
//     }
//     &::before {
//       position: absolute;
//       top: 17px;
//       left: -100px;

//       width: 80px;
//       height: 2px;

//       content: "";

//       background-color: #000;
//       @media #{$xs-layout} {
//         left: -40px;

//         width: 30px;
//       }
//     }
//     &::after {
//       position: absolute;
//       top: 17px;
//       right: -100px;

//       width: 80px;
//       height: 2px;

//       content: "";

//       background-color: #000;
//       @media #{$xs-layout} {
//         right: -40px;

//         width: 30px;
//       }
//     }
//   }
//   &.mb-55 {
//     @media #{$xs-layout} {
//       margin-bottom: 30px;
//     }
//   }

//   p {
//     font-size: 16px;
//     font-weight: 400;

//     margin-top: 10px;

//     &.grey {
//       color: #808080;
//     }
//   }

//   &.no-border {
//     h2 {
//       font-size: 36px;
//       &:before,
//       &:after {
//         display: none;
//       }
//     }
//   }
// }

.section-title-2 {
  h2 {
    font-size: 30px;
    font-weight: 600;

    position: relative;

    display: inline-block;

    margin: 0 0 15px;
    padding: 0 0 15px;

    color: #000000;
    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      width: 80px;
      height: 2px;
      margin: 0 auto;

      content: "";

      background-color: #000000;
    }
  }
  p {
    margin: 0;

    color: #333;
  }
  &.mb-60 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }
}

.section-title-3 {
  h4 {
    font-size: 24px;
    font-weight: 500;

    color: #000000;
  }
  &.mb-40 {
    @media #{$xs-layout} {
      margin-bottom: 20px;
    }
  }
}

.section-border {
  position: relative;

  overflow: hidden;

  margin-right: 94px;
  @media #{$xs-layout} {
    margin-right: 0;
  }
  &::before {
    position: absolute;
    z-index: 1;
    top: 11px;
    left: 0;

    width: 100%;
    height: 1px;

    content: "";
    transition: all 0.4s ease 0s;

    background-color: #d2d2d2;
    @media #{$xs-layout} {
      display: none;
    }
  }
}

.section-title-4 {
  position: relative;
  z-index: 999;
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    margin: 0;
    padding-right: 45px;

    letter-spacing: -0.25px;

    color: #242424;
    @media #{$xs-layout} {
      padding-right: 0;
    }
  }
}

.section-title-5 {
  h2 {
    font-size: 48px;
    line-height: 38px;

    margin: 0;

    color: #010101;
    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$xs-layout} {
      font-size: 30px;
      line-height: 27px;
    }
  }
  @media #{$xs-layout} {
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
}

.section-title-6 {
  h2 {
    font-family: $poppins;
    font-size: 24px;
    font-weight: bold;

    margin-bottom: 0;

    text-transform: uppercase;

    color: #010101;
  }
}

.section-title-7 {
  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 29px;

    margin: 0;

    color: #010101;
  }
}

.section-title-8 {
  h2 {
    font-family: $josefin;
    font-size: 36px;
    font-weight: 600;

    color: #010101;
  }
  p {
    width: 600px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &.bottom-border {
    position: relative;

    padding-bottom: 15px;
    &:before {
      position: absolute;
      bottom: 0;
      left: 50%;

      width: 50px;
      height: 1px;

      content: "";
      transform: translateX(-50%);

      background-color: #000;
    }
  }
}
