.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff; 
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
  
    .footer-item {
      flex: 1;
      color: #6c757d;
      text-align: center;
  
      svg {
        font-size: 24px;
      }
  
      p {
        margin: 5px 0 0;
        font-size: 12px;
  
        // Responsive font sizes
        @media (max-width: 768px) {
          font-size: 10px;
        }
  
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  