.video-area {
    position: relative;
    width: 100%;
    height: 100vh; // Fullscreen height
    overflow: hidden; // Hide any overflow outside the viewport
  
    .responsive-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      object-fit: cover; // Ensures video covers the full area without distortion
      transform: translate(-50%, -50%); // Center the video
    }
  }



  