.forgot-password-container {
    height: 100%;
    // background-color: #f7f8fc;
}

.form-wrapper {
    max-width: 400px;
    width: 100%;
    padding: 2rem;
    box-shadow: 0px 4px 8px rgb(0, 0,0 , 0.1);
    border-radius: 8px;
    background: #fff;

    h2 {
        text-align: center;
        margin-bottom: 1.5rem;
        color: #333;
    }

    .form-control {
        background-color: #f1f3f5;
        border: none;
        border-radius: 4px;

        &:focus {
            box-shadow: none;
            border-color: #5b6f7d;
        }
    }

    .btn-primary {
        background-color: #4a5b98;
        border: none;
        font-weight: bold;

        &:hover {
            background-color: #45b14f;
        }
    }
}