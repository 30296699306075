/*------ 24. login register Page  --------*/

.login-register-wrapper {
  .login-register-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .nav-item {
      position: relative;

      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 25px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
        }

        &:last-child::before {
          display: none;
        }
      }

      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .login-form-container {
    background: transparent none repeat scroll 0 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 2vh;
    padding: 80px;
    text-align: left;

    @media #{$lg-layout} {
      padding: 80px 50px;
    }

    @media #{$xs-layout} {
      padding: 40px 15px;
    }

    .login-register-form {
      form {

        input {
          background-color: transparent;
          border: 1px solid #ebebeb;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 15px;
          padding: 0 15px;
        }

        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }

        input[type="password"] {
          padding-right: 40px; // Add padding to avoid overlap with the eye icon
        }

        .password-toggle-btn {
          border: none;
          right: 15px;
          background: none;
          position: absolute;
          transform: translateY(-50%);
          right: 15px; // Position the button on the right side of the input field
          top: 37%; // Center the button vertically
          transform: translateY(-50%);
          cursor: pointer;
          font-size: 1.2rem;

          @media #{$lg-layout} {
            right: 20px; // Adjust if needed for large screens
          }

          @media #{$xs-layout} {
            right: 10px; // Adjust if needed for small screens
          }
        }

        .password-toggle-btn-register {
          border: none;
          background: none;
          position: absolute;
          right: 10px; 
          top: 37%; 
          transform: translateY(-50%); /* Adjust vertical alignment */
          cursor: pointer;
          font-size: 1.2rem;
          z-index: 1; /* Ensure the icon is above the input field */
        
          @media #{$lg-layout} {
            right: 15px; /* Adjust for large screens, keep it closer */
          }
        
          @media #{$xs-layout} {
            right: 10px; /* Adjust for small screens */
          }
        }
        


        .button-box {
          .login-toggle-btn {
            padding: 10px 0 19px;

            label {
              color: #242424;
              font-size: 15px;
              font-weight: 400;

              @media #{$xs-layout} {
                font-size: 14px;
              }
            }

            a {
              color: #242424;
              float: right;
              font-size: 15px;
              border-radius: 1vh;

              @media #{$xs-layout} {
                font-size: 14px;
              }

              transition: all 0.3s ease 0s;

              &:hover {
                color: $theme-color;
              }
            }
          }

          button {
            background-color: #30367D;
            border: medium none;
            border-radius: 1vh;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;

            &:hover {
              background-color: $theme-color;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.login-register-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.password-field-container {
  position: relative;
  display: flex;
  align-items: center;
}

.forgot-password {
  color: #45b14f;
}